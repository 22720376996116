<template>
    <section
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Form</div>
                </div>
            </div>
        </div>

        <div class="c-gravity-form">
            <div class="grid-container">
                <div class="grid-x grid-margin-x align-center">
                    <div class="small-12 medium-10 cell">
                        <div v-if="componentData.secondary_form.secondary_form" class="c-gravity-form__toggles-wrapper">
                            <div class="c-toggle-buttons" v-if="button">
                                <div class="c-toggle-buttons__container" :class="{ [`is-button-${button}`]: button }">
                                    <label
                                        class="c-toggle-buttons__button-container"
                                        @click="[(button = 'left'), (activeForm = 'primary')]">
                                        <span
                                            class="c-toggle-buttons__button"
                                            :class="{ 'is-active': button == 'left' }">
                                            <template v-if="componentData.primary_form.primary_form_toggle_title">
                                                {{ componentData.primary_form.primary_form_toggle_title }}
                                            </template>

                                            <template v-else>
                                                {{ componentData.primary_form.primary_form_title }}
                                            </template>
                                        </span>
                                    </label>

                                    <label
                                        class="c-toggle-buttons__button-container"
                                        @click="[(button = 'right'), (activeForm = 'secondary')]">
                                        <span
                                            class="c-toggle-buttons__button"
                                            :class="{ 'is-active': button == 'right' }">
                                            <template v-if="componentData.secondary_form.secondary_form_toggle_title">
                                                {{ componentData.secondary_form.secondary_form_toggle_title }}
                                            </template>

                                            <template v-else>
                                                {{ componentData.secondary_form.secondary_form_title }}
                                            </template>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div
                            class="c-gravity-form__form is-primary"
                            :class="[
                                {
                                    'is-active': activeForm == 'primary',
                                    'has-media': componentData.primary_form.primary_form_image,
                                },
                            ]">
                            <template v-if="componentData.primary_form.primary_form_title">
                                <h2
                                    v-if="componentData.settings.heading_level == 'h2'"
                                    class="c-gravity-form__form-title wow animate__animated animate__fadeIn"
                                    v-html="componentData.primary_form.primary_form_title"></h2>

                                <h3
                                    v-else-if="componentData.settings.heading_level == 'h3'"
                                    class="c-gravity-form__form-title wow animate__animated animate__fadeIn"
                                    v-html="componentData.primary_form.primary_form_title"></h3>

                                <div
                                    v-else
                                    class="c-gravity-form__form-title wow animate__animated animate__fadeIn"
                                    v-html="componentData.primary_form.primary_form_title"></div>
                            </template>

                            <div
                                v-if="componentData.primary_form.primary_form_intro"
                                class="c-gravity-form__form-intro s-wp-cms wow animate__animated animate__fadeIn"
                                v-html="componentData.primary_form.primary_form_intro"></div>

                            <template v-if="componentData.primary_form.primary_form_image">
                                <div class="c-gravity-form__form-content-wrapper">
                                    <div
                                        class="c-gravity-form__form-media"
                                        v-html="
                                            componentData.primary_form.primary_form_image.attachments['large']
                                        "></div>

                                    <div class="c-gravity-form__form-form">
                                        <ClientOnly>
                                            <PartialsContactForm
                                                v-if="componentData.primary_form.primary_form"
                                                :form_id="componentData.primary_form.primary_form"
                                                :redirect_url="componentData.primary_form.primary_form_redirect_url" />
                                        </ClientOnly>
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <ClientOnly>
                                    <PartialsContactForm
                                        v-if="componentData.primary_form.primary_form"
                                        :form_id="componentData.primary_form.primary_form"
                                        :redirect_url="componentData.primary_form.primary_form_redirect_url" />
                                </ClientOnly>
                            </template>
                        </div>

                        <div
                            v-if="componentData.secondary_form.secondary_form"
                            class="c-gravity-form__form is-secondary"
                            :class="[
                                {
                                    'is-active': activeForm == 'secondary',
                                    'has-media': componentData.secondary_form.secondary_form_image,
                                },
                            ]">
                            <template v-if="componentData.secondary_form.secondary_form_title">
                                <h2
                                    v-if="componentData.settings.heading_level == 'h2'"
                                    class="c-gravity-form__form-title"
                                    v-html="componentData.secondary_form.secondary_form_title"></h2>

                                <h3
                                    v-else-if="componentData.settings.heading_level == 'h3'"
                                    class="c-gravity-form__form-title"
                                    v-html="componentData.secondary_form.secondary_form_title"></h3>

                                <div
                                    v-else
                                    class="c-gravity-form__form-title"
                                    v-html="componentData.secondary_form.secondary_form_title"></div>
                            </template>

                            <div
                                v-if="componentData.secondary_form.secondary_form_intro"
                                class="c-gravity-form__form-intro s-wp-cms"
                                v-html="componentData.secondary_form.secondary_form_intro"></div>

                            <template v-if="componentData.secondary_form.secondary_form_image">
                                <div class="c-gravity-form__form-content-wrapper">
                                    <div
                                        class="c-gravity-form__form-media"
                                        v-html="
                                            componentData.secondary_form.secondary_form_image.attachments['large']
                                        "></div>

                                    <div class="c-gravity-form__form-form">
                                        <ClientOnly>
                                            <PartialsContactForm
                                                v-if="componentData.primary_form.primary_form"
                                                :form_id="componentData.secondary_form.secondary_form"
                                                :redirect_url="
                                                    componentData.secondary_form.secondary_form_redirect_url
                                                " />
                                        </ClientOnly>
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <ClientOnly>
                                    <PartialsContactForm
                                        v-if="componentData.primary_form.primary_form"
                                        :form_id="componentData.secondary_form.secondary_form"
                                        :redirect_url="componentData.secondary_form.secondary_form_redirect_url" />
                                </ClientOnly>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
    });

    const activeForm = ref("primary");
    const button = ref("left");

    // console.log("Component Data: ", props.componentData);
</script>
